import { ReportProblemSharp } from '@mui/icons-material';
import { SxProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import Typography from '@mui/material/Typography';
import { ChangeEventHandler, RefObject } from 'react';
import { KeyboardKeys } from '../enums/keyboard-keys.enum';
import React from 'react'
import { UtilsService } from '../../../services/utils.service';

interface Props {
    title?: string;
    placeholder: string;
    type: string;
    inputRef?: React.Ref<any> | undefined;
    onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    disabled?: boolean;
    onClick?: Function;
    error?: boolean;
    errorMessage?: string;
    borderRadius?: number;
    startAdornment?: any;
    fontSize?: number;
    endAdornment?: any;
    backgroundColor?: string;
    borderColor?: string;
    value?: string;
    mt?: number;
    onEnterPress?: Function;
    disaledColor?: string;
    noBorder?: boolean;
    multiline?: boolean;
    autoFocus?: boolean;
    containerWidth?: string;
    sx?: SxProps<Theme> | undefined;
}

export const Input = (props: Props) => {
    const keyPress = (event: any) => {
        if (event.key == KeyboardKeys.ENTER && props.onEnterPress) {
            props.onEnterPress();
        }
    };
    return (
        <Box
            sx={{
                mt: props.mt !== undefined ? `${props.mt}px` : '10px',
                width: props.containerWidth,
            }}
        >
            {props.title && <Typography align="left">{props.title}</Typography>}
            <FormControl fullWidth variant="outlined">
                <OutlinedInput
                    id={UtilsService.uuidv4()}
                    type={props.type}
                    onClick={() => {
                        props.onClick && props.onClick();
                    }}
                    autoFocus={props.autoFocus}
                    onKeyDown={keyPress}
                    onChange={props.onChange}
                    multiline={props.multiline}
                    value={props.value}
                    disabled={props.disabled}
                    inputProps={{
                        ref: props.inputRef,
                    }}
                    error={props.error}
                    sx={{
                        ...props.sx,
                        pt: 1,
                        pb: 1,
                        fontSize: props.fontSize ? props.fontSize : 16,
                        borderRadius: props.borderRadius,
                        backgroundColor: props.backgroundColor
                            ? props.backgroundColor
                            : '',

                        '& fieldset': {
                            borderColor: props.borderColor
                                ? props.borderColor
                                : '',

                            border: props.noBorder ? 'none !important' : '',
                        },
                        '&.Mui-focused': {
                            boxShadow: props.noBorder ? 'none !important' : '',
                        },
                    }}
                    placeholder={props.placeholder}
                    label={props.title}
                    endAdornment={props.endAdornment}
                    startAdornment={props.startAdornment}
                />

                {props.errorMessage && props.error && (
                    <Typography
                        sx={{ mt: 1, mb: 1 }}
                        color="error.main"
                        align="left"
                    >
                        {props.errorMessage}
                    </Typography>
                )}
            </FormControl>
        </Box>
    );
};
