import React from 'react'

interface IProps {
    size?: number;
}

export const Avator = (props: IProps) => {
    return (
        <svg
            width={props.size || 21}
            height={props.size || 21}
            viewBox="0 0 21 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M13.3794 8.37305C13.3794 9.96086 12.0922 11.248 10.5044 11.248V12.498C12.7826 12.498 14.6294 10.6512 14.6294 8.37305H13.3794ZM10.5044 11.248C8.91657 11.248 7.62939 9.96086 7.62939 8.37305H6.37939C6.37939 10.6512 8.22622 12.498 10.5044 12.498V11.248ZM7.62939 8.37305C7.62939 6.78523 8.91657 5.49805 10.5044 5.49805V4.24805C8.22622 4.24805 6.37939 6.09487 6.37939 8.37305H7.62939ZM10.5044 5.49805C12.0922 5.49805 13.3794 6.78523 13.3794 8.37305H14.6294C14.6294 6.09487 12.7826 4.24805 10.5044 4.24805V5.49805ZM7.87939 15.123H13.1294V13.873H7.87939V15.123ZM2.37939 10.998C2.37939 6.51073 6.01708 2.87305 10.5044 2.87305V1.62305C5.32672 1.62305 1.12939 5.82038 1.12939 10.998H2.37939ZM10.5044 2.87305C14.9917 2.87305 18.6294 6.51073 18.6294 10.998H19.8794C19.8794 5.82038 15.6821 1.62305 10.5044 1.62305V2.87305ZM18.6294 10.998C18.6294 13.3023 17.671 15.3816 16.1294 16.861L16.9949 17.7629C18.772 16.0576 19.8794 13.6566 19.8794 10.998H18.6294ZM16.1294 16.861C14.669 18.2626 12.6878 19.123 10.5044 19.123V20.373C13.0232 20.373 15.3111 19.3789 16.9949 17.7629L16.1294 16.861ZM13.1294 15.123C14.5236 15.123 15.6873 16.1161 15.9492 17.4338L17.1752 17.1902C16.7993 15.2988 15.1315 13.873 13.1294 13.873V15.123ZM10.5044 19.123C8.32098 19.123 6.33983 18.2626 4.87936 16.861L4.01385 17.7629C5.69773 19.3789 7.98562 20.373 10.5044 20.373V19.123ZM4.87936 16.861C3.33777 15.3816 2.37939 13.3023 2.37939 10.998H1.12939C1.12939 13.6566 2.23682 16.0576 4.01385 17.7629L4.87936 16.861ZM7.87939 13.873C5.87724 13.873 4.20947 15.2988 3.83359 17.1902L5.05962 17.4338C5.3215 16.1161 6.48516 15.123 7.87939 15.123V13.873Z"
                fill="#9C9FD0"
            />
            <path
                d="M12.5017 13.832H7.50174C5.88442 13.832 4.53612 14.9839 4.23242 16.512C5.72973 17.9489 7.76261 18.832 10.0017 18.832C12.2409 18.832 14.2738 17.9489 15.7711 16.512C15.4674 14.9839 14.1191 13.832 12.5017 13.832Z"
                fill="#9C9FD0"
            />
            <path
                d="M15.7711 16.512L16.2038 16.9629L16.4508 16.7259L16.3841 16.3901L15.7711 16.512ZM4.23242 16.512L3.61941 16.3901L3.55268 16.7259L3.79967 16.9629L4.23242 16.512ZM7.50174 14.457H12.5017V13.207H7.50174V14.457ZM15.3383 16.061C13.9527 17.3908 12.0732 18.207 10.0017 18.207V19.457C12.4086 19.457 14.5948 18.5071 16.2038 16.9629L15.3383 16.061ZM12.5017 14.457C13.8151 14.457 14.9114 15.3925 15.1581 16.6338L16.3841 16.3901C16.0234 14.5752 14.423 13.207 12.5017 13.207V14.457ZM10.0017 18.207C7.93028 18.207 6.05078 17.3908 4.66518 16.061L3.79967 16.9629C5.40868 18.5071 7.59493 19.457 10.0017 19.457V18.207ZM7.50174 13.207C5.58045 13.207 3.98011 14.5752 3.61941 16.3901L4.84543 16.6338C5.09213 15.3925 6.18838 14.457 7.50174 14.457V13.207Z"
                fill="#9C9FD0"
            />
            <path
                d="M14.0044 8.37305C14.0044 10.306 12.4374 11.873 10.5044 11.873C8.5714 11.873 7.00439 10.306 7.00439 8.37305C7.00439 6.44005 8.5714 4.87305 10.5044 4.87305C12.4374 4.87305 14.0044 6.44005 14.0044 8.37305Z"
                fill="#9C9FD0"
            />
            <path
                d="M13.3794 8.37305C13.3794 9.96087 12.0922 11.248 10.5044 11.248V12.498C12.7826 12.498 14.6294 10.6512 14.6294 8.37305H13.3794ZM10.5044 11.248C8.91658 11.248 7.62939 9.96087 7.62939 8.37305H6.37939C6.37939 10.6512 8.22622 12.498 10.5044 12.498V11.248ZM7.62939 8.37305C7.62939 6.78523 8.91658 5.49805 10.5044 5.49805V4.24805C8.22622 4.24805 6.37939 6.09487 6.37939 8.37305H7.62939ZM10.5044 5.49805C12.0922 5.49805 13.3794 6.78523 13.3794 8.37305H14.6294C14.6294 6.09487 12.7826 4.24805 10.5044 4.24805V5.49805Z"
                fill="#9C9FD0"
            />
        </svg>
    );
};
