import {
    Box,
    Button,
    Dialog,
    DialogContent,
    Paper,
    styled,
    Tooltip,
    Typography,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { createRef, useEffect, useRef, useState } from 'react';
import { Input } from '../../core/input';
import { IncomingMesage } from './incoming-message';
import { useChatbotService } from '../../../services/chatbot.service';
import SendIcon from '@mui/icons-material/Send';
import { IncomingMessageIcon } from '../incoming-message-icon';
import React from 'react';
import {
    IMessage,
    AIQueryChatResponse,
    Article,
    DocumentInsightChatSource,
    IChatbotConfig,
    IChatbotDesignConfig,
    LiveQueryResponseLLMData,
    AdditionalInfo,
    DocumentInsightChatSourceData,
} from '../../../services/interfaces';
import { UtilsService } from '../../../services/utils.service';
import { Reload } from '../../../components/icons/reload';
import { Avator } from '../../../components/icons/avator';
import { Citation } from '../citation';

const MessageWrapper = styled(Box)(() => ({
    maxWidth: '80%',
    width: 'fit-content',
    padding: 16,
    textAlign: 'left',
    border: '1px solid #EBECF6',
}));

export const ChatMessage = styled(Typography)(() => ({
    fontWeight: 400,
    color: '#003',
}));

const SentMessageWrapper = styled(MessageWrapper)(() => ({
    marginLeft: 'auto',
    borderRadius: '8px 0px 8px 8px',
}));

export const IncomingMessageWrapper = styled(MessageWrapper)(() => ({
    marginLeft: 8,
    background: '#F9F9FF',
    borderRadius: '0px 8px 8px 8px',
}));

export const ActionButton = styled(Button)(() => ({
    marginLeft: 2,
    padding: 0,
    minWidth: 0,
    '&:hover  path': {
        fill: '#4B4B4C',
    },
    '&:hover': {
        backgroundColor: 'transparent !important',
    },
}));

interface IProps {
    handleClose: Function;
    open: any;
    widgetSessionChatId: string;
    chatbotConfig: IChatbotConfig;
    setWidgetSessionChatId: (id: string) => void;
    userFileIds: string[];
}

//@ts-ignore
const PaperComponent = styled(Paper)(() => ({
    position: 'fixed !important',
    maxWidth: `calc(100% - 100px) !important`,
    right: 70,
    zIndex: 999999,
    bottom: 10,
}));

interface IQuery {
    queryId: string;
    query: string;
    createdAt: Date;
    response: string | null;
    articles: Article[] | null;
}

export const FILINGS_SECTIONS_MAP: { [key: string]: string } = {
    'MD and A': 'Management Discussion',

    Business: 'Business',

    'Risk Factors': 'Risk Factors',

    Discussion: 'Management Discussion',

    'Q&A': 'Question And Answers',
};

export const DocumentInsightsChatDialog = (props: IProps) => {
    const { handleClose, open } = props;
    let inputRef = useRef(false);
    const [lastSentMessageId, setLastSentMessageId] = useState<string>('');
    const [responseMessage, setResponseMessage] = useState<string | null>(null);

    const [messages, setMessages] = useState<IMessage[]>([]);
    const container = createRef();
    const { fetchDocumentInsightsQueryResponse, updateDocumentInsightChat } =
        useChatbotService();
    const [queryMessage, setQueryMessage] = useState<string | undefined>('');

    const sendMessage = async () => {
        if (!queryMessage) return;
        let query: IQuery = {
            queryId: UtilsService.uuidv4(),
            query: queryMessage,
            createdAt: new Date(),
            response: null,
            articles: null,
        };
        const messageId = UtilsService.uuidv4();
        setLastSentMessageId(messageId);
        setMessages([
            ...messages,
            {
                messageType: 'OUTGOING',
                messageID: messageId,
                vote: 'neutral',
                isBookmarked: false,
                data: {
                    query: queryMessage,
                },
            },
        ]);
        processMessage(queryMessage, query);
        setQueryMessage('');
    };

    const onResetClick = () => {
        setMessages([]);
        setResponseMessage(null);
    };

    const processMessage = async (question: string, query: IQuery) => {
        if (!queryMessage || !props.userFileIds) return;
        {
            const responseMessageId = UtilsService.uuidv4();
            let response = await fetchDocumentInsightsQueryResponse(
                query.query,
                props.widgetSessionChatId,
                (data: string) => {
                    if (data) {
                        let responses = data.split('\n');
                        // let res:TopicQueryResponse = JSON.parse(data);
                        processQueryResponse(
                            responses,
                            query,
                            responseMessageId
                        );
                    }
                }
            );
        }
    };

    const processResponseString = (responseString: string) => {
        let message = responseString;
        for (let i = 1; i < 10; i++) {
            message = message.split(` [${i}]`).join('');
        }
        return message;
    };

    const processResponseStringCitation = (
        responseString: string,
        sources: DocumentInsightChatSource[]
    ): {
        responseString: string;
        sources: DocumentInsightChatSource[];
    } => {
        let available = 0;
        for (let i = 0; i < sources.length; i++) {
            sources[i].id = i + 1;
            if (responseString.includes(`[${i + 1}]`)) {
                available++;
                sources[i].citationIndex = available;
                responseString = responseString
                    .split(`[${i + 1}]`)
                    .join(`[${available}]`);
            }
        }
        let r = {
            responseString,
            sources: sources.filter((v) => v.citationIndex),
            // sources,
        };
        return r;
    };

    const processQueryResponse = (
        responses: string[],
        query: IQuery,
        messageID: string
    ) => {
        let responseString = '';
        let widgetSessionChatId = '';
        let sources: DocumentInsightChatSource[] = [];
        for (let res of responses) {
            try {
                let parsedRes: AIQueryChatResponse = JSON.parse(res);
                switch (parsedRes.chunk) {
                    case 'SOURCES':
                        sources = (
                            parsedRes.data as DocumentInsightChatSourceData
                        ).sources;
                        break;
                    case 'ADDITIONAL_INFO':
                        const info = parsedRes.data as AdditionalInfo;
                        props.setWidgetSessionChatId(info.widgetSessionChatId);
                        widgetSessionChatId = info.widgetSessionChatId;
                        break;
                    case 'LLM':
                        responseString =
                            responseString +
                            (parsedRes.data as LiveQueryResponseLLMData).value;
                        setResponseMessage(
                            processResponseString(responseString)
                        );
                        break;
                    case 'TERMINATION':
                        
                        setResponseMessage(null);
                        let data = processResponseStringCitation(
                            responseString,
                            //@ts-ignore
                            sources
                        );
                        pushMessage({
                            messageType: 'INCOMING',
                            messageID,
                            vote: 'neutral',
                            isBookmarked: false,
                            widgetSessionChatId: widgetSessionChatId,
                            data: {
                                answer: data.responseString,
                                sources:
                                    responseString.includes(
                                        `Unfortunately, I'm not able to confidently provide an answer to your question`
                                    ) ||
                                    responseString.includes(
                                        `Unfortunately I am not able to confidently provide an answer to your question.`
                                    )
                                        ? undefined
                                        : data.sources,
                                query: query.query,
                            },
                        });

                        break;
                }
                // if (parsedRes.chunk) {
                //     responseString = responseString + parsedRes.chunk;
                //     setResponseMessage(processResponseString(responseString));
                // }
                // if (Array.isArray(parsedRes)) {
                //     sources = parsedRes as DocumentInsightChatSource[];
                // }

                // if (parsedRes.widgetSessionChatId) {
                //     props.setWidgetSessionChatId(parsedRes.widgetSessionChatId);
                //     setResponseMessage(null);
                //     let data = processResponseStringCitation(
                //         responseString,
                //         sources
                //     );
                //     pushMessage({
                //         messageType: 'INCOMING',
                //         messageID,
                //         vote: 'neutral',
                //         isBookmarked: false,
                //         widgetSessionChatId: parsedRes.widgetSessionChatId,
                //         data: {
                //             answer: data.responseString,
                //             sources:
                //                 responseString.includes(
                //                     `Unfortunately, I'm not able to confidently provide an answer to your question`
                //                 ) ||
                //                 responseString.includes(
                //                     `Unfortunately I am not able to confidently provide an answer to your question.`
                //                 )
                //                     ? undefined
                //                     : data.sources,
                //             question: query.question,
                //         },
                //     });
                // }
            } catch (error) {
                // console.error(error);
            }
        }
        query.response = responseString;
    };

    const pushMessage = (message: IMessage) => {
        setMessages((messages) => [
            ...messages.filter(
                (m: IMessage) => m.messageID !== message.messageID
            ),
            message,
        ]);
    };

    const getSourcesText = (
        sources: DocumentInsightChatSource[] | undefined
    ) => {
        if (!sources || !sources.length) {
            return '';
        }
        return `Sources: \n\n${sources
            .filter((s: DocumentInsightChatSource) => s.citationIndex)
            .map(
                (s: DocumentInsightChatSource) =>
                    `Source [${s.citationIndex}]: Page No - ${s.pageNo}\nSummary: ${s.summary}\n`
            )
            .join('\n')}`;
    };

    useEffect(() => {
        let _messages: IMessage[] = [];
        for (let m of props.chatbotConfig.designConfig.data.config.text.welcomeMessages.sort(
            (a, b) => (a.order < b.order ? -1 : 1)
        )) {
            _messages.push(m.message);
        }
        setMessages([..._messages]);
    }, [props.chatbotConfig]);

    const handleCopyToClipboard = (message: IMessage) => {
        if (!message.data.answer || !message.data.sources) return;
        let data = processResponseStringCitation(
            message.data.answer,
            message.data.sources
        );
        let text = `Question: ${message.data.query}\nAnswer: ${
            data.responseString
        }\n\n${getSourcesText([...data.sources])}`;
        navigator.clipboard.writeText(text);
    };

    const updateMessage = async (
        message: IMessage,
        action?: 'bookmark' | 'upvote' | 'downvote'
    ) => {
        if (!message.widgetSessionChatId) return;
        let vote: 'neutral' | 'upvote' | 'downvote' = 'neutral';
        let _messages = messages.map((m: IMessage) => {
            if (m.messageID == message.messageID) {
                m.isBookmarked =
                    action == 'bookmark' ? !m.isBookmarked : m.isBookmarked;
                vote = m.vote;
                if (m.vote == action) {
                    m.vote = 'neutral';
                } else if (action == 'upvote' || action == 'downvote') {
                    m.vote = action;
                }
            }
            return m;
        });
        setMessages([..._messages]);
        let status = await updateDocumentInsightChat(
            message.widgetSessionChatId,
            message.isBookmarked,
            message.vote,
            'DOCUMENT_INSIGHTS'
        );
        if (!status) {
            let _messages = messages.map((m: IMessage) => {
                if (m.messageID == message.messageID) {
                    m.isBookmarked =
                        action == 'bookmark' ? !m.isBookmarked : m.isBookmarked;
                    m.vote = vote;
                }
                return m;
            });
            setMessages([..._messages]);
        }
    };

    useEffect(() => {
        if (container.current) {
            //@ts-ignore
            container.current.scrollTo(0, container.current.scrollHeight);
        }
    }, [messages, open, responseMessage]);

    useEffect(() => {
        setQueryMessage('');
    }, [props.userFileIds]);

    return open ? (
        <PaperComponent
            elevation={4}
            sx={{
                width: `${props.chatbotConfig.designConfig.data.config.appearance.shape.container.width}px !important`,
                borderRadius: `${props.chatbotConfig.designConfig.data.config.appearance.shape.container.radius}px !important`,
            }}
        >
            <Box
                sx={{
                    p: 0,
                    maxWidth: '100% !important',
                    width: props.chatbotConfig.designConfig.data.config
                        .appearance.shape.container.width,
                }}
            >
                <Box
                    sx={{
                        borderRadius: `${props.chatbotConfig.designConfig.data.config.appearance.shape.container.radius}px ${props.chatbotConfig.designConfig.data.config.appearance.shape.container.radius}px 0px 0px`,
                        backgroundColor:
                            props.chatbotConfig.designConfig.data.config
                                .appearance.colors.primary,
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box px={4} pt={1}>
                        <Box py={3} display="flex">
                            <Box>
                                <Typography
                                    color={
                                        props.chatbotConfig.designConfig.data
                                            .config.appearance.colors.text
                                    }
                                    fontSize={24}
                                    fontWeight={600}
                                >
                                    {
                                        props.chatbotConfig.designConfig.data
                                            .config.text.title
                                    }
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box pt={5}>
                        <Tooltip placement="top" arrow title="Reset">
                            <Button
                                onClick={onResetClick}
                                sx={{ minWidth: 35, mr: 4 }}
                            >
                                <Reload
                                    color={
                                        props.chatbotConfig.designConfig.data
                                            .config.appearance.colors.text
                                    }
                                />
                            </Button>
                        </Tooltip>
                    </Box>
                </Box>

                <Box
                    ref={container}
                    sx={{
                        height: `${Math.max(
                            window.innerHeight - 450,
                            400
                        )}px !important`,
                        overflow: 'auto',
                        pb: 5,
                    }}
                >
                    <Box>
                        {messages.map((message: IMessage) => (
                            <Box p={2} pb={0}>
                                {message.messageType == 'INCOMING' && (
                                    <Box display="flex">
                                        <IncomingMessageIcon
                                            imageUrl={
                                                props.chatbotConfig.designConfig
                                                    .data.config.appearance
                                                    .images.chatIcon
                                            }
                                            color={
                                                props.chatbotConfig.designConfig
                                                    .data.config.appearance
                                                    .colors.primary
                                            }
                                        />
                                        <IncomingMesage
                                            message={message}
                                            chatbotConfig={props.chatbotConfig}
                                            onCopyToClipBoardClick={() =>
                                                handleCopyToClipboard(message)
                                            }
                                            onBookmarkClick={() =>
                                                updateMessage(
                                                    message,
                                                    'bookmark'
                                                )
                                            }
                                            onUpVoteClick={() =>
                                                updateMessage(message, 'upvote')
                                            }
                                            onDownVoteClick={() =>
                                                updateMessage(
                                                    message,
                                                    'downvote'
                                                )
                                            }
                                        />
                                    </Box>
                                )}
                                {message.messageType == 'OUTGOING' && (
                                    <Box display="flex">
                                        <SentMessageWrapper>
                                            <ChatMessage
                                                sx={{
                                                    fontSize:
                                                        props.chatbotConfig
                                                            .designConfig.data
                                                            .config.appearance
                                                            .font.fontSize,
                                                }}
                                            >
                                                {message.data.query}
                                            </ChatMessage>
                                        </SentMessageWrapper>

                                        <Box pr={1} pl={2}>
                                            <Avator size={30} />
                                        </Box>
                                    </Box>
                                )}
                            </Box>
                        ))}
                        {responseMessage && (
                            <Box p={2} pb={0}>
                                <Box display="flex">
                                    <IncomingMessageIcon
                                        imageUrl={
                                            props.chatbotConfig.designConfig
                                                .data.config.appearance.images
                                                .chatIcon
                                        }
                                        color={
                                            props.chatbotConfig.designConfig
                                                .data.config.appearance.colors
                                                .primary
                                        }
                                    />
                                    <IncomingMessageWrapper>
                                        <ChatMessage
                                            sx={{
                                                fontSize:
                                                    props.chatbotConfig
                                                        .designConfig.data
                                                        .config.appearance.font
                                                        .fontSize,
                                            }}
                                        >
                                            <Citation
                                                onCitationClick={() => {}}
                                                text={responseMessage}
                                                disableCitation={
                                                    !props.chatbotConfig
                                                        .withCitations
                                                }
                                            />
                                        </ChatMessage>
                                    </IncomingMessageWrapper>
                                </Box>
                            </Box>
                        )}
                        {!responseMessage &&
                            messages.length > 0 &&
                            messages[messages.length - 1].messageType ==
                                'OUTGOING' && (
                                <Box p={2} pb={0}>
                                    <Box display="flex">
                                        <IncomingMessageIcon
                                            imageUrl={
                                                props.chatbotConfig.designConfig
                                                    .data.config.appearance
                                                    .images.chatIcon
                                            }
                                            color={
                                                props.chatbotConfig.designConfig
                                                    .data.config.appearance
                                                    .colors.primary
                                            }
                                        />
                                        <IncomingMessageWrapper
                                            mb={4}
                                            width="50% !important"
                                        >
                                            <CircularProgress size={20} />
                                        </IncomingMessageWrapper>
                                    </Box>
                                </Box>
                            )}
                    </Box>
                </Box>
                <Box
                    sx={{
                        px: 2,
                        maxHeight: 100,
                        overflow: 'auto',
                    }}
                >
                    <Box
                        sx={{
                            border: '1px solid #D7D9EC',
                            borderRadius: 2,
                            boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.08)',
                        }}
                    >
                        <Input
                            type="text"
                            mt={0}
                            fontSize={
                                props.chatbotConfig.designConfig.data.config
                                    .appearance.font.fontSize
                            }
                            inputRef={inputRef}
                            noBorder
                            disabled={!props.chatbotConfig.widgetId}
                            value={queryMessage}
                            onChange={(e) => {
                                //@ts-ignore
                                setQueryMessage(e.target.value);
                            }}
                            onEnterPress={sendMessage}
                            endAdornment={
                                <Button
                                    sx={{
                                        p: 1,
                                        fontSize: 18,
                                        borderRadius: 10,
                                        minWidth: 0,
                                    }}
                                    variant="text"
                                    disabled={!queryMessage}
                                    onClick={sendMessage}
                                >
                                    <SendIcon
                                        sx={{
                                            fontSize: 28,
                                            color: queryMessage
                                                ? props.chatbotConfig
                                                      .designConfig.data.config
                                                      .appearance.colors.primary
                                                : '#ccc',
                                        }}
                                    />
                                </Button>
                            }
                            placeholder={
                                props.chatbotConfig.designConfig.data.config
                                    .text.inputPlaceholder ||
                                'Type your question here...'
                            }
                        />
                    </Box>
                </Box>
                <Box py={1} textAlign="center">
                    <Typography variant="subtitle1" fontSize={14}>
                        Powered By{' '}
                        <a
                            href="https://www.photoninsights.com"
                            target="_blank"
                        >
                            {' '}
                            Photon Insights
                        </a>
                    </Typography>
                </Box>
            </Box>
        </PaperComponent>
    ) : null;
};
