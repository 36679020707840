import { App } from './components/app';
import React from 'react';
import ReactDOM from 'react-dom';
import { shadowRootElement } from './themes';

// app shadow root

const appRoot = document.querySelector('#photon-chat-widget');
appRoot.attachShadow({
    mode: 'open',
});

ReactDOM.render(<App />, shadowRootElement);

// // render into some specific element:
// if(document)
// render(<App />, document.getElementById('photon-chat-widget'))
