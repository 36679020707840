export default {
    'code[class*="language-"]': {
        background: 'rgb(249, 249, 249)',
        color: 'rgb(56, 57, 66)',
        fontFamily:
            '"Fira Code", "Fira Mono", Menlo, Consolas, "DejaVu Sans Mono", monospace',
        direction: 'ltr',
        textAlign: 'left',
        whiteSpace: 'pre',
        wordSpacing: 'normal',
        wordBreak: 'normal',
        lineHeight: '1.5',
        MozTabSize: '2',
        OTabSize: '2',
        tabSize: '2',
        WebkitHyphens: 'none',
        MozHyphens: 'none',
        msHyphens: 'none',
        hyphens: 'none',
    },
    'pre[class*="language-"]': {
        background: 'rgb(249, 249, 249)',
        color: 'rgb(56, 57, 66)',
        fontFamily:
            '"Fira Code", "Fira Mono", Menlo, Consolas, "DejaVu Sans Mono", monospace',
        direction: 'ltr',
        textAlign: 'left',
        whiteSpace: 'pre',
        wordSpacing: 'normal',
        wordBreak: 'normal',
        lineHeight: '1.5',
        MozTabSize: '2',
        OTabSize: '2',
        tabSize: '2',
        WebkitHyphens: 'none',
        MozHyphens: 'none',
        msHyphens: 'none',
        hyphens: 'none',
        padding: '1em',
        margin: '0.5em 0',
        overflow: 'auto',
        borderRadius: '0.3em',
    },
    'code[class*="language-"]::-moz-selection': {
        background: 'rgb(229, 229, 229)',
        color: 'inherit',
    },
    'code[class*="language-"] *::-moz-selection': {
        background: 'rgb(229, 229, 229)',
        color: 'inherit',
    },
    'pre[class*="language-"] *::-moz-selection': {
        background: 'rgb(229, 229, 229)',
        color: 'inherit',
    },
    'code[class*="language-"]::selection': {
        background: 'rgb(229, 229, 229)',
        color: 'inherit',
    },
    'code[class*="language-"] *::selection': {
        background: 'rgb(229, 229, 229)',
        color: 'inherit',
    },
    'pre[class*="language-"] *::selection': {
        background: 'rgb(229, 229, 229)',
        color: 'inherit',
    },
    ':not(pre) > code[class*="language-"]': {
        padding: '0.2em 0.3em',
        borderRadius: '0.3em',
        whiteSpace: 'normal',
    },
    comment: {
        color: 'rgb(159, 160, 166)',
        fontStyle: 'italic',
    },
    prolog: {
        color: 'rgb(159, 160, 166)',
    },
    cdata: {
        color: 'rgb(159, 160, 166)',
    },
    doctype: {
        color: 'rgb(56, 57, 66)',
    },
    punctuation: {
        color: 'rgb(56, 57, 66)',
    },
    entity: {
        color: 'rgb(56, 57, 66)',
        cursor: 'help',
    },
    'attr-name': {
        color: 'rgb(182, 106, 0)',
    },
    'class-name': {
        color: 'rgb(182, 106, 0)',
    },
    boolean: {
        color: 'rgb(182, 106, 0)',
    },
    constant: {
        color: 'rgb(182, 106, 0)',
    },
    number: {
        color: 'rgb(182, 106, 0)',
    },
    atrule: {
        color: 'rgb(182, 106, 0)',
    },
    keyword: {
        color: '#af00db',
    },
    property: {
        color: 'rgb(227, 85, 73)',
    },
    tag: {
        color: '#800000',
    },
    symbol: {
        color: 'rgb(227, 85, 73)',
    },
    deleted: {
        color: 'rgb(227, 85, 73)',
    },
    important: {
        color: 'rgb(227, 85, 73)',
    },
    selector: {
        color: '#a31515',
    },
    string: {
        color: '#a31515',
    },
    char: {
        color: '#a31515',
    },
    builtin: {
        color: '#a31515',
    },
    inserted: {
        color: '#a31515',
    },
    regex: {
        color: '#a31515',
    },
    'attr-value': {
        color: '#a31515',
    },
    'attr-value > .token.punctuation': {
        color: '#a31515',
    },
    variable: {
        color: 'rgb(0 81 255)',
    },
    operator: {
        color: 'rgb(0 81 255)',
    },
    function: {
        color: 'rgb(0 81 255)',
    },
    url: {
        color: 'rgb(0, 131, 187)',
    },
    'attr-value > .token.punctuation.attr-equals': {
        color: 'rgb(56, 57, 66)',
    },
    'special-attr > .token.attr-value > .token.value.css': {
        color: 'rgb(56, 57, 66)',
    },
    '.language-css .token.selector': {
        color: 'rgb(227, 85, 73)',
    },
    '.language-css .token.property': {
        color: 'rgb(56, 57, 66)',
    },
    '.language-css .token.function': {
        color: 'rgb(0, 131, 187)',
    },
    '.language-css .token.url > .token.function': {
        color: 'rgb(0, 131, 187)',
    },
    '.language-css .token.url > .token.string.url': {
        color: '#a31515',
    },
    '.language-css .token.important': {
        color: '#af00db',
    },
    '.language-css .token.atrule .token.rule': {
        color: '#af00db',
    },
    '.language-javascript .token.operator': {
        color: '#af00db',
    },
    '.language-javascript .token.template-string > .token.interpolation > .token.interpolation-punctuation.punctuation':
        {
            color: 'rgb(255, 81, 101)',
        },
    '.language-json .token.operator': {
        color: 'rgb(56, 57, 66)',
    },
    '.language-json .token.null.keyword': {
        color: 'rgb(182, 106, 0)',
    },
    '.language-markdown .token.url': {
        color: 'rgb(56, 57, 66)',
    },
    '.language-markdown .token.url > .token.operator': {
        color: 'rgb(56, 57, 66)',
    },
    '.language-markdown .token.url-reference.url > .token.string': {
        color: 'rgb(56, 57, 66)',
    },
    '.language-markdown .token.url > .token.content': {
        color: 'rgb(0 81 255)',
    },
    '.language-markdown .token.url > .token.url': {
        color: 'rgb(0, 131, 187)',
    },
    '.language-markdown .token.url-reference.url': {
        color: 'rgb(0, 131, 187)',
    },
    '.language-markdown .token.blockquote.punctuation': {
        color: 'rgb(159, 160, 166)',
        fontStyle: 'italic',
    },
    '.language-markdown .token.hr.punctuation': {
        color: 'rgb(159, 160, 166)',
        fontStyle: 'italic',
    },
    '.language-markdown .token.code-snippet': {
        color: '#a31515',
    },
    '.language-markdown .token.bold .token.content': {
        color: 'rgb(182, 106, 0)',
    },
    '.language-markdown .token.italic .token.content': {
        color: '#af00db',
    },
    '.language-markdown .token.strike .token.content': {
        color: 'rgb(227, 85, 73)',
    },
    '.language-markdown .token.strike .token.punctuation': {
        color: 'rgb(227, 85, 73)',
    },
    '.language-markdown .token.list.punctuation': {
        color: 'rgb(227, 85, 73)',
    },
    '.language-markdown .token.title.important > .token.punctuation': {
        color: 'rgb(227, 85, 73)',
    },
    bold: {
        fontWeight: 'bold',
    },
    italic: {
        fontStyle: 'italic',
    },
    namespace: {
        Opacity: '0.8',
    },
    'token.tab:not(:empty):before': {
        color: 'rgba(56, 57, 66, 0.2)',
    },
    'token.cr:before': {
        color: 'rgba(56, 57, 66, 0.2)',
    },
    'token.lf:before': {
        color: 'rgba(56, 57, 66, 0.2)',
    },
    'token.space:before': {
        color: 'rgba(56, 57, 66, 0.2)',
    },
    'div.code-toolbar > .toolbar.toolbar > .toolbar-item': {
        marginRight: '0.4em',
    },
    'div.code-toolbar > .toolbar.toolbar > .toolbar-item > button': {
        background: 'rgb(229, 229, 229)',
        color: 'rgb(105, 107, 118)',
        padding: '0.1em 0.4em',
        borderRadius: '0.3em',
    },
    'div.code-toolbar > .toolbar.toolbar > .toolbar-item > a': {
        background: 'rgb(229, 229, 229)',
        color: 'rgb(105, 107, 118)',
        padding: '0.1em 0.4em',
        borderRadius: '0.3em',
    },
    'div.code-toolbar > .toolbar.toolbar > .toolbar-item > span': {
        background: 'rgb(229, 229, 229)',
        color: 'rgb(105, 107, 118)',
        padding: '0.1em 0.4em',
        borderRadius: '0.3em',
    },
    'div.code-toolbar > .toolbar.toolbar > .toolbar-item > button:hover': {
        background: 'rgb(229, 229, 229)',
        color: 'rgb(56, 57, 66)',
    },
    'div.code-toolbar > .toolbar.toolbar > .toolbar-item > button:focus': {
        background: 'rgb(229, 229, 229)',
        color: 'rgb(56, 57, 66)',
    },
    'div.code-toolbar > .toolbar.toolbar > .toolbar-item > a:hover': {
        background: 'rgb(229, 229, 229)',
        color: 'rgb(56, 57, 66)',
    },
    'div.code-toolbar > .toolbar.toolbar > .toolbar-item > a:focus': {
        background: 'rgb(229, 229, 229)',
        color: 'rgb(56, 57, 66)',
    },
    'div.code-toolbar > .toolbar.toolbar > .toolbar-item > span:hover': {
        background: 'rgb(229, 229, 229)',
        color: 'rgb(56, 57, 66)',
    },
    'div.code-toolbar > .toolbar.toolbar > .toolbar-item > span:focus': {
        background: 'rgb(229, 229, 229)',
        color: 'rgb(56, 57, 66)',
    },
    '.line-highlight.line-highlight': {
        background: 'rgba(56, 57, 66, 0.05)',
    },
    '.line-highlight.line-highlight:before': {
        background: 'rgb(229, 229, 229)',
        color: 'rgb(56, 57, 66)',
        padding: '0.1em 0.6em',
        borderRadius: '0.3em',
        boxShadow: '0 2px 0 0 rgba(0, 0, 0, 0.2)',
    },
    '.line-highlight.line-highlight[data-end]:after': {
        background: 'rgb(229, 229, 229)',
        color: 'rgb(56, 57, 66)',
        padding: '0.1em 0.6em',
        borderRadius: '0.3em',
        boxShadow: '0 2px 0 0 rgba(0, 0, 0, 0.2)',
    },
    'pre[id].linkable-line-numbers.linkable-line-numbers span.line-numbers-rows > span:hover:before':
        {
            backgroundColor: 'rgba(56, 57, 66, 0.05)',
        },
    '.line-numbers.line-numbers .line-numbers-rows': {
        borderRightColor: 'rgba(56, 57, 66, 0.2)',
    },
    '.command-line .command-line-prompt': {
        borderRightColor: 'rgba(56, 57, 66, 0.2)',
    },
    '.line-numbers .line-numbers-rows > span:before': {
        color: 'rgb(159, 160, 166)',
    },
    '.command-line .command-line-prompt > span:before': {
        color: 'rgb(159, 160, 166)',
    },
    '.rainbow-braces .token.token.punctuation.brace-level-1': {
        color: 'rgb(227, 85, 73)',
    },
    '.rainbow-braces .token.token.punctuation.brace-level-5': {
        color: 'rgb(227, 85, 73)',
    },
    '.rainbow-braces .token.token.punctuation.brace-level-9': {
        color: 'rgb(227, 85, 73)',
    },
    '.rainbow-braces .token.token.punctuation.brace-level-2': {
        color: '#a31515',
    },
    '.rainbow-braces .token.token.punctuation.brace-level-6': {
        color: '#a31515',
    },
    '.rainbow-braces .token.token.punctuation.brace-level-10': {
        color: '#a31515',
    },
    '.rainbow-braces .token.token.punctuation.brace-level-3': {
        color: 'rgb(0 81 255)',
    },
    '.rainbow-braces .token.token.punctuation.brace-level-7': {
        color: 'rgb(0 81 255)',
    },
    '.rainbow-braces .token.token.punctuation.brace-level-11': {
        color: 'rgb(0 81 255)',
    },
    '.rainbow-braces .token.token.punctuation.brace-level-4': {
        color: '#af00db',
    },
    '.rainbow-braces .token.token.punctuation.brace-level-8': {
        color: '#af00db',
    },
    '.rainbow-braces .token.token.punctuation.brace-level-12': {
        color: '#af00db',
    },
    'pre.diff-highlight > code .token.token.deleted:not(.prefix)': {
        backgroundColor: 'rgba(255, 81, 101, 0.15)',
    },
    'pre > code.diff-highlight .token.token.deleted:not(.prefix)': {
        backgroundColor: 'rgba(255, 81, 101, 0.15)',
    },
    'pre.diff-highlight > code .token.token.deleted:not(.prefix)::-moz-selection':
        {
            backgroundColor: 'rgba(255, 81, 101, 0.25)',
        },
    'pre.diff-highlight > code .token.token.deleted:not(.prefix) *::-moz-selection':
        {
            backgroundColor: 'rgba(255, 81, 101, 0.25)',
        },
    'pre > code.diff-highlight .token.token.deleted:not(.prefix)::-moz-selection':
        {
            backgroundColor: 'rgba(255, 81, 101, 0.25)',
        },
    'pre > code.diff-highlight .token.token.deleted:not(.prefix) *::-moz-selection':
        {
            backgroundColor: 'rgba(255, 81, 101, 0.25)',
        },
    'pre.diff-highlight > code .token.token.deleted:not(.prefix)::selection': {
        backgroundColor: 'rgba(255, 81, 101, 0.25)',
    },
    'pre.diff-highlight > code .token.token.deleted:not(.prefix) *::selection':
        {
            backgroundColor: 'rgba(255, 81, 101, 0.25)',
        },
    'pre > code.diff-highlight .token.token.deleted:not(.prefix)::selection': {
        backgroundColor: 'rgba(255, 81, 101, 0.25)',
    },
    'pre > code.diff-highlight .token.token.deleted:not(.prefix) *::selection':
        {
            backgroundColor: 'rgba(255, 81, 101, 0.25)',
        },
    'pre.diff-highlight > code .token.token.inserted:not(.prefix)': {
        backgroundColor: 'rgba(25, 255, 90, 0.15)',
    },
    'pre > code.diff-highlight .token.token.inserted:not(.prefix)': {
        backgroundColor: 'rgba(25, 255, 90, 0.15)',
    },
    'pre.diff-highlight > code .token.token.inserted:not(.prefix)::-moz-selection':
        {
            backgroundColor: 'rgba(25, 255, 90, 0.25)',
        },
    'pre.diff-highlight > code .token.token.inserted:not(.prefix) *::-moz-selection':
        {
            backgroundColor: 'rgba(25, 255, 90, 0.25)',
        },
    'pre > code.diff-highlight .token.token.inserted:not(.prefix)::-moz-selection':
        {
            backgroundColor: 'rgba(25, 255, 90, 0.25)',
        },
    'pre > code.diff-highlight .token.token.inserted:not(.prefix) *::-moz-selection':
        {
            backgroundColor: 'rgba(25, 255, 90, 0.25)',
        },
    'pre.diff-highlight > code .token.token.inserted:not(.prefix)::selection': {
        backgroundColor: 'rgba(25, 255, 90, 0.25)',
    },
    'pre.diff-highlight > code .token.token.inserted:not(.prefix) *::selection':
        {
            backgroundColor: 'rgba(25, 255, 90, 0.25)',
        },
    'pre > code.diff-highlight .token.token.inserted:not(.prefix)::selection': {
        backgroundColor: 'rgba(25, 255, 90, 0.25)',
    },
    'pre > code.diff-highlight .token.token.inserted:not(.prefix) *::selection':
        {
            backgroundColor: 'rgba(25, 255, 90, 0.25)',
        },
    '.prism-previewer.prism-previewer:before': {
        borderColor: 'rgb(242, 242, 242)',
    },
    '.prism-previewer-gradient.prism-previewer-gradient div': {
        borderColor: 'rgb(242, 242, 242)',
        borderRadius: '0.3em',
    },
    '.prism-previewer-color.prism-previewer-color:before': {
        borderRadius: '0.3em',
    },
    '.prism-previewer-easing.prism-previewer-easing:before': {
        borderRadius: '0.3em',
    },
    '.prism-previewer.prism-previewer:after': {
        borderTopColor: 'rgb(242, 242, 242)',
    },
    '.prism-previewer-flipped.prism-previewer-flipped.after': {
        borderBottomColor: 'rgb(242, 242, 242)',
    },
    '.prism-previewer-angle.prism-previewer-angle:before': {
        background: 'rgb(255, 255, 255)',
    },
    '.prism-previewer-time.prism-previewer-time:before': {
        background: 'rgb(255, 255, 255)',
    },
    '.prism-previewer-easing.prism-previewer-easing': {
        background: 'rgb(255, 255, 255)',
    },
    '.prism-previewer-angle.prism-previewer-angle circle': {
        stroke: 'rgb(56, 57, 66)',
        strokeOpacity: '1',
    },
    '.prism-previewer-time.prism-previewer-time circle': {
        stroke: 'rgb(56, 57, 66)',
        strokeOpacity: '1',
    },
    '.prism-previewer-easing.prism-previewer-easing circle': {
        stroke: 'rgb(56, 57, 66)',
        fill: 'transparent',
    },
    '.prism-previewer-easing.prism-previewer-easing path': {
        stroke: 'rgb(56, 57, 66)',
    },
    '.prism-previewer-easing.prism-previewer-easing line': {
        stroke: 'rgb(56, 57, 66)',
    },
};
