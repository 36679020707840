import { PILogo } from '../icons/pi-logo';
import { Box } from '@mui/material';
import React from 'react'

interface IProps {
    color: string;
    imageUrl: string;
}

export const IncomingMessageIcon = (props: IProps) => {
    return (
        <Box p={1}>
            <Box
                sx={{
                    backgroundColor: props.color,
                    width: 26,
                    height: 26,
                    display: 'inline-flex',
                    borderRadius: '50%',
                }}
            >
                <img
                    style={{
                        backgroundColor: props.color,
                        width: 26,
                        height: 26,
                        display: 'inline-flex',
                        borderRadius: '50%',
                    }}
                    src={props.imageUrl}
                />
            </Box>
        </Box>
    );
};
