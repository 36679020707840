// @ts-ignore
import { addMinutes, format, formatDistance } from 'date-fns';

export class UtilsService {
    static isValidEmail(email: string) {
        var mailformat =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (email.match(mailformat)) {
            return true;
        } else {
            return false;
        }
    }

    static timeSinceUTC = (date: Date): string => {
        date = addMinutes(date, -1 * date.getTimezoneOffset());
        return formatDistance(date, new Date(), { addSuffix: true });
    };
    static convertBackslashDelimitersToDollar = (md: string): string => {
        // 1) Inline math:  \(...\) => $...$
        md = md.replace(/\\\(([^\)]*?)\\\)/g, '$$$1$$');

        // 2) Block math:  \[...\] => $$...$$
        md = md.replace(/\\\[([\s\S]*?)\\\]/g, '$$$$$1$$$$');

        return md;
    };

    static timeSince = (date: Date): string => {
        return formatDistance(date, new Date(), { addSuffix: true });
    };

    static getFileType = (title: string): string => {
        const extension = title.split('.').pop();
        return extension?.toLocaleUpperCase() || '';
    };

    static getFileSize = (fSize: number): string => {
        var fSExt = new Array('Bytes', 'KB', 'MB', 'GB');
        let i = 0;
        while (fSize > 900) {
            fSize /= 1024;
            i++;
        }

        return Math.round(fSize * 100) / 100 + ' ' + fSExt[i];
    };
    static uuidv4() {
        //@ts-ignore
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
            (
                c ^
                (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
            ).toString(16)
        );
    }
    static formatStringDate(dateString: string) {
        try {
            let date = new Date(dateString);
            return UtilsService.formatDate(date);
        } catch (error) {
            return '';
        }
    }

    static formatStringDateForPotentialIndicators(dateString: string) {
        try {
            let date = new Date(dateString);
            date = addMinutes(date, date.getTimezoneOffset());
            return format(date, 'MMM dd yyyy');
        } catch (error) {
            return '';
        }
    }

    static getMonday = (d: Date) => {
        var day = d.getDay(),
            diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
        return new Date(d.setDate(diff));
    };

    static formatDate(date: Date) {
        try {
            date = addMinutes(date, date.getTimezoneOffset());
            return format(date, 'do MMM, yyyy');
        } catch (error) {
            return '';
        }
    }

    static formatStringDateWithoutOffset(dateString: string) {
        try {
            let date = new Date(dateString);
            return format(date, 'do MMM, yyyy');
        } catch (error) {
            return '';
        }
    }

    static formatDateWithoutYear(date: Date) {
        try {
            date = addMinutes(date, date.getTimezoneOffset());
            return format(date, 'do MMM');
        } catch (error) {
            return '';
        }
    }

    static getSessionStorage = (key: string) => {
        return sessionStorage.getItem(key);
    };

    static setSessionStorage = (key: string, value: string) => {
        return sessionStorage.setItem(key, value);
    };

    static isUrlValid = (url: string) => {
        var res = url.match(
            /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
        );
        if (res == null) return false;
        else return true;
    };

    static formatDateWithoutYearWithoutOffset(date: Date) {
        try {
            return format(date, 'do MMM');
        } catch (error) {
            return '';
        }
    }

    static formatDateForNetworkCall(date: Date) {
        date = addMinutes(date, date.getTimezoneOffset());
        return format(date, 'yyyy-MM-dd');
    }

    static formatDateForDateTimeNetworkCall(date: Date) {
        date = addMinutes(date, date.getTimezoneOffset());
        return date.toJSON().split('.')[0];
    }

    static formatDateForNetworkCallWithoutOffset(date: Date) {
        return format(date, 'yyyy-MM-dd');
    }

    static getDateWeeksAgo(weeks: number) {
        let days = weeks * 7;
        let d = new Date();
        d.setDate(d.getDate() - days);
        return d;
    }

    static getDateDaysAgo(days: number) {
        let d = new Date();
        d.setDate(d.getDate() - days);
        return d;
    }

    static getDatesBetweenDates = (
        stringStartDate: string,
        stringEndDate: string
    ): string[] => {
        const startDate = new Date(stringStartDate);
        const endDate = new Date(stringEndDate);
        var dateArray: string[] = new Array();
        var currentDate = new Date(startDate);
        while (currentDate <= endDate) {
            let d = new Date(currentDate);
            dateArray.push(UtilsService.formatDateForNetworkCall(d));
            currentDate.setDate(currentDate.getDate() + 1);
        }
        return dateArray;
    };

    static getUTCDateFromString = (date: string) => {
        let d = new Date(date);
        return d;
    };

    static formatStringDateWithoutYear(stringDate: string) {
        try {
            let date = new Date(stringDate);
            return UtilsService.formatDateWithoutYear(date);
        } catch (error) {
            return '';
        }
    }

    static waitUntilTokenFetched = (): Promise<void> => {
        return new Promise((resolve, reject) => {
            var interval = setInterval(() => {
                //@ts-ignore
                if (!window.fetchingToken) {
                    clearInterval(interval);
                    resolve();
                }
            }, 100);
        });
    };

    static convertSentimentToPercentage = (value: number) => {
        value = Math.floor(value * 10000);
        return value / 100;
    };

    static getRandomDarkColor = (): string => {
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += Math.floor(Math.random() * 10);
        }
        return color;
    };

    static getForstNonZeroValue = (arr: number[]) => {
        for (let val of arr) {
            if (val > 0) return val;
        }
        return 0;
    };

    static firstCharUppercase = (str: string) => {
        str = str.toLowerCase();
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    static trimText = (text: string, charCount: number) => {
        return text.length > charCount
            ? text.substring(0, charCount - 3) + '...'
            : text;
    };

    static getRandomLightColor = () => {
        var letters = 'BCDEF'.split('');
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * letters.length)];
        }
        return color;
    };

    static getNextNonZero = (arr: number[], start: number) => {
        for (let index = start + 1; index < arr.length; index++) {
            if (arr[index] != 0) {
                return index;
            }
        }
        return arr.length - 1;
    };

    static convertPlotLine = (arr: number[]) => {
        for (let index = 1; index < arr.length; index++) {
            if (arr[index] == 0) {
                let nextNonZero = UtilsService.getNextNonZero(arr, index - 1);
                if (nextNonZero < 0 || nextNonZero < index) {
                    continue;
                } else {
                    arr[index] =
                        arr[index - 1] +
                        (arr[nextNonZero] - arr[index - 1]) /
                            (nextNonZero - index + 1);
                }
            }
        }
        return [...arr];
    };

    static highlightContent = (
        sectionId: string,
        defaultCoulor: string,
        highlightColor: string
    ) => {
        setTimeout(() => {
            //@ts-ignore
            document.getElementById(sectionId).style.backgroundColor =
                highlightColor;
        }, 900);
        setTimeout(() => {
            //@ts-ignore
            document.getElementById(sectionId).style.backgroundColor =
                defaultCoulor;
        }, 1900);
        setTimeout(() => {
            //@ts-ignore
            document.getElementById(sectionId).style.backgroundColor =
                highlightColor;
        }, 2400);
        setTimeout(() => {
            //@ts-ignore
            document.getElementById(sectionId).style.backgroundColor =
                defaultCoulor;
        }, 3400);
    };
}
