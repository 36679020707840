import React from 'react'
import { DocumentInsightsChatBox } from './chat-box/document-insight';
import ThemeCustomization from '../themes';

export const App = () => {
    return (
        <ThemeCustomization>
            <DocumentInsightsChatBox userFileIds={[]} />
        </ThemeCustomization>
    );
};
