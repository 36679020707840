import React from 'react'

interface IProps {
    color?: string;
}
export const Reload = (props: IProps) => (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_2206_9999)">
            <path
                d="M2.67773 0.668857C2.67896 0.392717 2.4561 0.167863 2.17996 0.166631C1.90383 0.165399 1.67897 0.388256 1.67774 0.664395L1.6717 2.01708C1.66945 2.52032 1.66756 2.94007 1.70441 3.27752C1.74307 3.63148 1.82946 3.9577 2.04877 4.24109C2.10816 4.31782 2.17353 4.38962 2.24422 4.45582C2.28585 4.49481 2.32932 4.53185 2.37451 4.56682C2.6579 4.78613 2.98412 4.87253 3.33807 4.91118C3.67553 4.94804 4.09525 4.94615 4.59849 4.94389L5.9512 4.93786C6.22734 4.93663 6.4502 4.71177 6.44897 4.43563C6.44773 4.15949 6.22288 3.93663 5.94674 3.93787L4.62293 3.94377C4.08332 3.94618 3.72086 3.94704 3.44663 3.91709C3.42601 3.91484 3.40628 3.91245 3.38738 3.90992C3.66305 3.5991 3.96964 3.3163 4.30229 3.06637C5.333 2.29199 6.61351 1.83329 8.0026 1.83329C11.4084 1.83329 14.1693 4.5942 14.1693 7.99996C14.1693 11.4057 11.4084 14.1666 8.0026 14.1666C4.59685 14.1666 1.83594 11.4057 1.83594 7.99996C1.83594 7.72382 1.61208 7.49996 1.33594 7.49996C1.0598 7.49996 0.835938 7.72382 0.835938 7.99996C0.835938 11.958 4.04456 15.1666 8.0026 15.1666C11.9606 15.1666 15.1693 11.958 15.1693 7.99996C15.1693 4.04192 11.9606 0.833293 8.0026 0.833293C6.3895 0.833293 4.89956 1.36684 3.70162 2.26688C3.33945 2.53898 3.00393 2.8446 2.69963 3.17914L2.6985 3.16896C2.66856 2.89474 2.66942 2.53228 2.67182 1.99267L2.67773 0.668857Z"
                fill={props.color || '#29319B'}
            />
        </g>
        <defs>
            <clipPath id="clip0_2206_9999">
                <rect width="16" height="16" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
