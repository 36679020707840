import { Box, Button, Divider, styled, Typography } from '@mui/material';
import { Circle } from '../../icons/circle';
import { Link } from 'react-router-dom';
import React from 'react'
import { DocumentInsightChatSource } from '../../../services/interfaces';
import { useEffect, useState } from 'react';
import { UtilsService } from '../../../services/utils.service';

interface IProps {
    sources: DocumentInsightChatSource[];
    messageId: string;
    isBookmarked?: boolean;
    vote?: string;
    selectedCitationIndex?: number;
    onCopyToClipBoardClick?: () => void;
    onUpVoteClick?: () => void;
    onDownVoteClick?: () => void;
    onBookmarkClick?: () => void;
}

const SourceWrapper = styled(Box)(() => ({
    border: '1px solid #D7D9EC',
    borderRadius: 8,
    paddingRight: 8,
    paddingLeft: 8,
    fontSize: 14,
    lineHeight: '24px',
    color: '#4B4B4C',
    marginRight: 8,
    cursor: 'pointer',
}));

export const ActionButton = styled(Button)(() => ({
    marginLeft: 2,
    padding: 0,
    minWidth: 0,
    '&:hover  path': {
        fill: '#4B4B4C',
    },
    '&:hover': {
        backgroundColor: 'transparent !important',
    },
}));

export const NavigationLink = styled(Link)(() => ({
    color: '#000033',
    textDecoration: 'none',
}));

export const ResponseSources = (props: IProps) => {
    const [selectedSource, setSelectedSource] = useState<number | null>(null);
    const scrollToSection = (citationIndex: number) => {
        setTimeout(() => {
            //@ts-ignore
            document
                .getElementById(`${props.messageId}citation${citationIndex}`)
                .scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest',
                    inline: 'start',
                });
        }, 0);
    };
    const updateSelectedSource = (value: number) => {
        setSelectedSource(value);
    };
    useEffect(() => {
        if (props.selectedCitationIndex && props.sources) {
            let source = props.sources.filter(
                (v) => v.citationIndex == props.selectedCitationIndex
            )[0];
            updateSelectedSource(source.id);
            scrollToSection(props.selectedCitationIndex);
        }
    }, [props.selectedCitationIndex, props.sources]);
    return props.sources.length > 0 ? (
        <Box>
            <Box py={4}>
                <Divider />
            </Box>
            <Box>
                <Box display="flex" mb={selectedSource ? 2 : 0}>
                    {props.sources.map((value: DocumentInsightChatSource) => (
                        <SourceWrapper
                            onClick={() =>
                                updateSelectedSource(
                                    selectedSource != value.id ? value.id : null
                                )
                            }
                            sx={{
                                border:
                                    selectedSource == value.id
                                        ? '1px solid #29319B'
                                        : '1px solid #D7D9EC',
                                color:
                                    selectedSource == value.id
                                        ? '#29319B'
                                        : '#4B4B4C',
                                fontWeight:
                                    selectedSource == value.id ? 600 : 400,
                            }}
                        >
                            Source{' '}
                            {value.citationIndex ? value.citationIndex : 1}
                        </SourceWrapper>
                    ))}
                </Box>

                {props.sources
                    .filter(
                        (value: DocumentInsightChatSource) =>
                            value.id == selectedSource
                    )
                    .map(
                        (
                            value: DocumentInsightChatSource,
                            index: number,
                            arr: DocumentInsightChatSource[]
                        ) => (
                            <Box
                                id={`${props.messageId}citation${value.citationIndex}`}
                                mt={1}
                            >
                                {value.answerSource == 'ARTICLE' ? (
                                    <Box display="flex">
                                        {value.icon && (
                                            <img
                                                style={{
                                                    maxWidth: 20,
                                                    maxHeight: 16,
                                                    marginLeft: '4px',
                                                    marginRight: '4px',
                                                }}
                                                src={value.icon}
                                            />
                                        )}
                                        <Typography
                                            fontSize={10}
                                            variant="subtitle2"
                                            color="#A5A6AB"
                                        >
                                            {value.source}{' '}
                                            {value.datetime && (
                                                <Box component="span">
                                                    <Circle
                                                        mt={0}
                                                        h={8}
                                                        mx={2}
                                                        w={8}
                                                    />{' '}
                                                    {UtilsService.formatStringDate(
                                                        value.datetime
                                                    )}
                                                </Box>
                                            )}
                                        </Typography>
                                    </Box>
                                ) : (
                                    <Typography
                                        fontSize={10}
                                        variant="subtitle2"
                                        color="#A5A6AB"
                                    >
                                        {value.fileName}{' '}
                                        {value.pageNo && (
                                            <Box component="span">
                                                <Circle
                                                    mt={0}
                                                    h={8}
                                                    mx={2}
                                                    w={8}
                                                />{' '}
                                                {`Page ${value.pageNo}`}
                                            </Box>
                                        )}
                                    </Typography>
                                )}
                                {arr.length > 1 && (
                                    <Typography
                                        sx={{
                                            border: '1px solid #a5a6ab',
                                            backgroundColor: '#a5a6ab',
                                            borderRadius: '50%',
                                            fontSize: 10,
                                            px: 1,
                                            color: '#fff',
                                            py: 0.25,
                                            mr: 1,
                                            position: 'relative',
                                            top: '-1px',
                                        }}
                                        component="span"
                                    >
                                        {value.citationIndex}
                                    </Typography>
                                )}

                                {value.title && (
                                    <a
                                        href={value.url}
                                        target="_blank"
                                        style={{
                                            color: '#29319B',
                                            fontFamily: 'Open Sans',
                                            fontSize: '12px',
                                            fontStyle: 'normal',
                                            fontWeight: 600,
                                            lineHeight: '24px',
                                            overflow: 'hidden',
                                            textDecoration: 'none',
                                            wordBreak: 'break-word',
                                            display: '-webkit-box',
                                            //@ts-ignore
                                            '-webkit-box-orient': 'vertical',
                                            '-webkit-line-clamp': '1',
                                            '-webkit-box-pack': 'center',
                                        }}
                                    >
                                        {value.title}
                                    </a>
                                )}
                                {value.summary.split('\n').map((text) => (
                                    <Box>
                                        <Typography
                                            variant="caption"
                                            color="#4B4B4C"
                                        >
                                            {text}
                                        </Typography>
                                    </Box>
                                ))}
                            </Box>
                        )
                    )}
            </Box>
        </Box>
    ) : null;
};
